import React, { FC } from "react"
import { graphql, HeadFC, PageProps } from "gatsby";
import { BaseLayout } from "@layouts";
import { videosTv } from "@data";
import { SEO, YouTubePlayer } from "@components";
import classNames from "classnames";
import "./index.scss"

type VideoTvAppearancesData = Queries.VideoTvAppearancesQuery
type VideoTvAppearancesContext = {
    locale: string
}

const VideoTvAppearances: FC<PageProps<VideoTvAppearancesData, VideoTvAppearancesContext>> = ( { data } ) => {
    return (
        <BaseLayout>
            <h1>Videos & TV Appearances</h1>
            <div data-theme={ 'dark' }>
                <div className={ 'grid-videos container box' }>
                    { videosTv.map( ( video, i ) => {
                        const thumb = data.thumbnails.images.find( thumb => thumb.name === video.id ) ?? data.placeHolder
                        return (
                            <div className={ classNames( 'video-item', i < 1 && "span" ) } key={ video.id }>
                                <YouTubePlayer id={ video.id } placeholderImage={ thumb!.childImageSharp!.gatsbyImageData }/>
                                <div className={ 'box' }>
                                    <h2>{ video.title }</h2>
                                    <p>{ video.subtitle }</p>
                                </div>
                            </div>
                        )
                    } ) }
                </div>
            </div>
        </BaseLayout>
    )
}

export default VideoTvAppearances

export const Head: HeadFC = ( { location } ) =>
    <SEO
        title={ "Video & TV Appearances" }
        pathname={ location.pathname }
        description={ "Watch Maplerun's Official Music Videos, TV appearances, Interviews & Lyric Videos " }
    />


export const query = graphql`
    query VideoTvAppearances {
        placeHolder: file(relativePath: {eq: "youtube-video-placeholder.jpg"}) {
            childImageSharp {
                gatsbyImageData
            }
        }
        thumbnails: allFile(filter: {relativeDirectory: {eq: "video-thumbnails"}}) {
            images: nodes {
                name
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
    }`
